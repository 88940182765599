.Login {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Login h1 {
    margin-top: 18vh;
}

.Login form {
    
}

.Login form label {
    font-size: 18px;
    margin: 1% 0 0 -43vw;
    text-align: left;
}

.Login form input {
    width: 50vw;
    margin: 1% 25% 1% 25%;
}

.loginForm-buttons {

}

.passwordSettings {
    color: #FFF;
    font-size: 15px;
    cursor: pointer;
    margin-top: 1%;
}