.Homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5vh 5% 1% 5%;
    height: 100%;
}

.intro h1 {
    text-shadow: 2px 2px #16115e;
    font-size: 5rem;
    font-weight: 700;
}

.intro p {
    color: #FFF;
    font-size: 1rem;
    font-weight: 500;
}


.download-section {

}

.download-section p {
    font-size: 20px;
    font-style: italic;
}

.download-section div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.download-section div img {
    width: 100%;
    max-width: 155px;
    height: auto;
    margin-left: 2%;
    cursor: pointer;
}

.Homepage .loader {
    margin-top: 15vh;
}

.Homepage .loader p {
    font-size: 20px;
    font-style: italic;
}

.Homepage .loader img {
    max-width: 255px;
    width: 100%;
    height: auto;
}

.Homepage footer {
    margin-top: 5%;
    bottom: 0;
}