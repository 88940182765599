* {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

html, body {
  background-color: #1A1A1A;
  color: #ffb703;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-size: 15px;
  padding: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
  cursor: pointer;
}

.logo {
  max-width: 355px;
  width: 100%;
  height: auto;
}

/* BOOTSTRAP OVERRIDE */

.btn-primary {
  color: #ffb703;
  background-color: #101828;
  border: none;
  box-shadow: 2px 2px 2px 2px #000;
}

.btn-primary:hover {
  color: #1A1A1A;
  background-color: #ffb703;
}

.btn-primary:active {
  color: #1A1A1A !important;
  background-color: #ffb703 !important;
  border: #000 solid 2px;
}