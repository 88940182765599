.UploadMusic {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 8vh 2% 0 2%;
}

.UploadMusic {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 8vh;
}

.UploadMusic input {
    margin-bottom: 5%;
}

.UploadMusic .uploadMusic {
    margin: 1% 20% 5% 20%;
}


.UploadMusic .mp3File, .UploadMusic .albumFile {
    text-align: center;
    margin-bottom: 5%;
}