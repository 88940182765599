.MusicPlayer {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.goBackBtn {
    display: flex;
    justify-content: start;
    align-items: start;
    margin: 1% 0 0 1%;
}

.MusicPlayer .card {
    color: #FFF;
    background: none;
    border: none;
    margin-top: 12vh;
}

.MusicPlayer .card-body {
    
}

.MusicPlayer .card-title {
    
}

.MusicPlayer .albumCoverPlayer {
    max-width: 255px;
    width: 100%;
    height: auto;
}

.MusicPlayer .loader {
    position: fixed;
    background-color: #FFF;
    color: #000;
    width: 70vw;
    height: 1%;
    border-radius: 25px;
    left: 15%;
    right: 15%;
}

#progressBar {
    background-color: #ffb703;
}

input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 80%;
    background-color: #ffb703;
}

input[type="range"]::-webkit-slider-thumb, input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0.1rem;
    border-radius: 100%;
    background-color: #FFF;
    background: #FFF;
    color: #FFF;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track, input[type="range"]::-moz-range-track {
    background-color: #e7b537;
}

/* progress::-webkit-progress-bar, progress::-moz-progress-bar {
    background: #ffb703;
} */

.musicDuration {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: end;
    margin: 2% 15vw 0 0;
}

/* Music Player Controls */
.MusicPlayer .musicPlayerControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

.MusicPlayer .musicPlayerControls i {
    margin-right: 5%;
    font-size: 5rem;
    cursor: pointer;
}

.MusicPlayer .musicPlayerControls i:hover {
    color: #ffb703;
}