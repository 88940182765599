.Settings {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Settings button {
    width: 50vw;
    padding: 10px 15px;
    font-size: 15px;
    margin-top: 2%;
}

.Settings div {
    width: 80vw;
}

.Settings div p {
    margin-top: 5%;
}