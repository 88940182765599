.MainApp {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.searchBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.searchBar input {
    width: 50vw;
    margin: 1% 5% 1% 0;
}

.searchBar div {
    display: grid;
    grid-template-columns: auto auto;
}

.searchBar div button {
    font-size: 15px;
    padding: 5px 15px;
}

.logout {
    font-size: 15px;
    font-weight: 700;
}

/* Music Card */
