.NavBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    justify-content: space-evenly;
    font-size: 2rem;
    bottom: 0;
    color: #FFF;
    background-color: #2A2A2A;
    width: 100%;
    height: 5%;
}

.NavBar-item {
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.NavBar-item:hover {
    background-color: #ffb703;
    color: #1A1A1A;
}