.MusicCard {
    display: flex;
    flex-direction: column;
}

.MusicCard .card {
    display: grid;
    grid-template-columns: 20% 70% 10%;
    color: #FFF;
    background: none;
    border: none;
    margin: 0 10vw 5% 5%;
}

.MusicCard .card-body {
    text-align: left;
    float: left;
    left: 0;
    top: 0;
}

.MusicCard .card-title {
    color: #ffb703;
    font-size: 1rem;
    font-weight: 900;
}

.MusicCard .albumCover {
    max-width: 255px;
    width: 100%;
    height: auto;
    margin-top: 2vh;
}

.MusicCard .musicCardSettings {
    
}

.MusicCard .musicCardSettings button {
    font-size: 15px;
    padding: 3px 10px;
    margin-top: 2vh;
}